
.get_btn {
  width: 175px;
  height: 57px;
  border-radius: 40px;
  background-color: #00BBDC;
  border: none;
  color: white;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}

