* {
  border: none;
  padding: none;
  box-sizing: border-box;
}
.home-container {
  padding-top: 3%;
}

.home_style {
  font-family: "Roboto", serif;
  font-size: 64px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  color: #312b2b;
}

.home_stylish {
  font-family: "Lato";
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #312b2b;
}
/* .animatiom-image {
  margin-left: auto;
}

.animatiom-image img {
  width: 100%;
}
.image-section {
  --childs: 3;
  --gap: 10px;
  display: flex;
  gap: var(--gap);
  justify-content: flex-end;
}

.box {
  width: calc((100% / var(--childs)));
  height: 12cm;
  background-color: white;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: cover;
  border-radius: 20px;
  transition: width 300ms 50ms ease-in-out;
}

.box:hover,
.box.hovered {
  width: calc((100% / (var(--childs) - 2)));
  width: 50%;
}
.box-1 {
  background-image: url("../Images/screenshots/1.png");
  width: 19%;
}

.box-2 {
  background-image: url("../Images/screenshots/2.png");
  width: 19%;
}

.box-3 {
  background-image: url("../Images/screenshots/3.png");
  width: 19%;
} */
.App_btn {
  width: 175px;
  height: 57px;
  border-radius: 40px;
  background-color: #345473;
  border: none;
  color: white;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}
.App_btn:hover {
  background-color: white;
  color: black;
}
.Play_btn {
  width: 175px;
  height: 57px;
  border-radius: 40px;
  background-color: white;
  border: none;
  color: black;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}
.Play_btn:hover {
  background-color: #345473;
  color: white;
}
.button-group {
  display: flex;
  gap: 25px;
}
.hovered {
  background-color: #345473;
  color: black;
}

.animated-screenshot {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 1;
  transform: scale(1);
  border-radius: 6px;
  border: 4px solid black;
}

.animated-screenshot:hover {
  transform: scale(1.05);
}

.animated-screenshot.fade-out {
  opacity: 0;
  transform: scale(0.95);
}

@media (max-width: 1192px) {
  .animatiom-image {
    width: 100%;
    display: block;
    padding: 0;
  }
  .home_style {
    font-size: 60px;
    font-weight: 500;
    line-height: 70px;
    letter-spacing: 0em;
    color: #312b2b;
  }

  .home_stylish {
    
    font-size: 25px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    

    color: #312b2b;
  }
  .home-container {
    padding-top: 20px;
  }
  .button-group {
    display: flex;
    margin: 15px;
  }
  .animatiom-image img {
    width: 100%;
    margin: auto;
  }
  .image-section {
    --childs: 3;
    --gap: 10px;
    display: flex;
    gap: var(--gap);
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .animatiom-image {
    width: 100%;
    display: block;
  }
  .home_style {
   
    font-size: 50px;
    font-weight: 500;
    line-height: 70px;
    letter-spacing: 0em;
    color: #312b2b;
  }

  .home_stylish {
    
    font-size: 19px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    

    color: #312b2b;
  }
  .home-container {
    padding-top: 20px;
  }
  .button-group {
    display: flex;
    margin: 15px;
  }
  .animatiom-image img {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 767.98px) {
}

@media (max-width: 575px) {
  .animatiom-image {
    width: 100%;
    display: block;
  }
  .home_style {
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    color: #312b2b;
    margin-top: 10px;
  }

  .home_stylish {
   
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    

    color: #312b2b;
  }
  .home-container {
    padding-top: 0px;
  }
  .button-group {
    display: flex;
    margin: 15px;
  }
  .animatiom-image img {
    display: none;
  }
  .image-section {
    --childs: 3;
    --gap: 10px;
    display: flex;
    gap: var(--gap);
    justify-content: center;
  }

  .App_btn {
    width: 175px;
    height: 57px;
    border-radius: 40px;
    background-color: #345473;
    border: none;
    color: white;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }
  .App_btn:hover {
    background-color: white;
    color: black;
  }
  .Play_btn {
    width: 175px;
    height: 57px;
    border-radius: 40px;
    background-color: white;
    border: none;
    color: black;
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }
  .Play_btn:hover {
    background-color: #345473;
    color: white;
  }
}
