.benifit_card {
  background-color:  #00BBDC;
  /* background-color: rgba(0, 187, 220, 0.2);  */
  box-shadow: 0px 0px 0px 0px;
  padding: 60px 100px 55px 40px;
  color: #233b53;
  border-radius: 10px;
  border-radius: 10px 0 0px 10px;
  position: relative;
}

.benifit_card_corner {
  background-color: #345473;
  padding: 20px 50px 50px 30px;
  border-radius: 10px;
  position: absolute;
  top: -30px;
  left: -30px;
}
.main-saction {
  display: flex;
  gap: 10px;
}


.contact-form-text {
  margin: 5px 0;
  background: white;
  width: 100%;
  border: 3px solid #f5f5f5;
  border-radius: 18px;
  padding: 20px 30px 25px 40px;
  outline: none;
  color: #123054;
  transition: 0.5s;
}

.contact-form-text:focus {
  box-shadow: 0 0 10px 4px #00bbdc;
}
.Touch-head {
  color: #312b2b;
  font-size: 60px;
}
.Touch-para {
  color: #123054;
}

textarea.contact-form-text {
  resize: none;
}
.submit-button {
  width: 175px;
  height: 57px;
  border-radius: 40px;
  background-color: #345473;
  border: none;
  color: white;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
}

.submit-button:hover {
  background-color: #345473;
}

.icon-contact {
  background-color: #312b2b;
  font-size: 40px;
  color: #00bbdc;
  border-radius: 50%;
  padding: 5px;
}
.social-icon:hover {
  background-color: #00bbdc;
  color: white;
}
.contact-text {
  font-size: 25px;
  color: #233b53;
  font-weight: 600;
}
.Gotouch-hero{
  padding-left: 110px;
  padding-right: 110px;
}
.benifit_card_hero{
  margin-top: 70px;
  padding: 0;

}
.Gotouch-hero{
  margin-top: 32px;
}

@media only screen and (max-width: 1192px) {
  .animatiom-image {
    width: 100%;
    display: block;
  }
  .Responsive_card_hero {
    margin: auto;
    padding-top: 50px;
  }
  .benifit_card {
    background-color:  #00BBDC;
    /* Set the background color with decreased opacity */
    box-shadow: 0px 0px 0px 0px;
    padding: 80px 100px 100px 70px;
    color: #233b53;
    border-radius: 10px;
    border-radius: 10px 0 0px 10px;
    position: relative;
  }
  .Gotouch-hero {
    padding-left: 81px !important;
    padding-right: 81px !important;
  }
  .rightdiv-hero { 
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .icon-contact {
    display: none; /* Hidden on larger screens */
  }
  .contact-text {
    font-size: 25px;

    color: #233b53;
    font-weight: 600;
  }
}
@media only screen and (max-width: 992px) {
  .animatiom-image {
    width: 100%;
    display: block;
  }
  .Responsive_card_hero {
    margin: auto;
    padding-top: 50px;
  }
  .benifit_card {
    background-color:  #00BBDC;
    /* Set the background color with decreased opacity */
    box-shadow: 0px 0px 0px 0px;
    padding: 80px 100px 100px 70px;
    color: #233b53;
    border-radius: 10px;
    border-radius: 10px 0 0px 10px;
    position: relative;
  }
  .Gotouch-hero {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .rightdiv-hero { 
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .icon-contact {
    display: none; /* Hidden on larger screens */
  }
  .contact-text {
    font-size: 40px;
    color: #233b53;
    font-weight: 600;
  }
}
@media only screen and (max-width: 768px) {
  .animatiom-image {
    width: 100%;
    display: block;
  }
  .Responsive_card_hero {
    margin: auto;
    padding-top: 50px;
  }
  .benifit_card {
    background-color:  #00BBDC;
    /* Set the background color with decreased opacity */
    box-shadow: 0px 0px 0px 0px;
    padding: 80px 100px 100px 70px;
    color: #233b53;
    border-radius: 10px;
    border-radius: 10px 0 0px 10px;
    position: relative;
  }
  .Gotouch-hero {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .rightdiv-hero { 
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .icon-contact {
    display: block; /* Hidden on larger screens */
  }
  .contact-text {
    font-size: 18px;
    color: #233b53;
    font-weight: 600;
  }
}

@media only screen and (max-width: 575px) {
  /* Styles specific to smaller screens */
  .Gotouch-hero {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .benifit_card {
    background-color: #00bbdc;
    box-shadow: 0px 0px 0px 0px;
    padding: 80px 100px 100px 40px; /* Overriding padding */
    color: #233b53;
    border-radius: 10px;
  }
  .icon-contact {
    display: none; /* Displayed on smaller screens */
    background-color: #312b2b;
    font-size: 30px;
    color: #00bbdc;
    border-radius: 50%;
    padding: 5px;
  }
  .contact-text {
    font-size: 17px; /* Adjusted font size */
  }
  .Touch-head {
    color: #312b2b;
    font-size: 30px;
    padding: 10px;
  }
  .rightdiv-hero { 
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}


@media only screen and (max-width: 280px) {
  .contact-text {
    font-size: 12px !important;
    color: #233b53;
    font-weight: 600;
    margin-top: 10px;
  }
  .benifit_card {
    background-color:  #00BBDC;
    box-shadow: 0px 0px 0px 0px;
    padding:30px;
    color: #233b53;
    border-radius: 10px;
    border-radius: 10px 0 0px 10px;
    position: relative;
  }
}
