

.future_scope_head{
    color: #233C54;
    line-height: 60px;

}
.gaming_space{
    color:  white;
}
.future_scope_para{
    color:#312B2B;
    font-weight: 500;
    margin-top: 20px;

}
.Feature_btn {
    width: 175px;
    height: 57px;
    border-radius: 40px;
    background-color:#345473;
    border: none;
    color:white;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }
  .Feature_play {
    width: 175px;
    height: 57px;
    border-radius: 40px;
    background-color:white;
    border: none;
    color:#312B2B;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
  }
  .robot{
    width: 100%;
    height: 13cm;
  }
  .Feature_play:hover{
    background-color:#345473;
    color: white;
    
  }
  .hovered {
    background-color: white;
    color:black;
  
  }
  .Application-group{
    margin-top: 50px;
    display:flex;
    gap: 20px;
  }
  .introduce-column{
    margin-left: auto;
  }
  @media (max-width: 575px) {
    .introduce-column{
      margin-top: 20px;
    }
    .future_scope_head{
      color: #233C54;
      line-height: 40px;
  }
  .future_scope_para{
    margin-top: 20px;
    color:#312B2B;
    font-weight: 500;
    font-size: 22px;
    }
    .Application-group{
      display:flex;
      gap: 10px;
    }
    .Feature_btn {
      border-radius: 40px;
      background-color:#345473;
      border: none;
      color:white;
      font-family: Lato;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0em;
    }
    .Application-group-responsive{
      margin-top: 10px;
    }
    .future_scope_para{
      color:#312B2B;
      font-weight: 500;
      margin-top: 20px;
  }

 
 }
 @media (max-width:280px) {
  .Application-group{
    display:block !important;
      }
      .Application-group-responsive{
        margin-top: 20px;
      }
}
  @media (max-width: 768px) {

  
}
  @media (max-width: 1192px) {
    .Application-group-responsive{
      margin-left: 20px;
    }
    .future_scope_para{
      color:#312B2B;
      font-weight: 500;
      margin-top: 20px;
  
  }

  }
  

