.search_engine{
   font-family: Lato;
   font-weight: 700;
   font-size: 17px;
   line-height: 22px;
   color: #123054;
     margin-top: 6%;
   }
   .icon-alignment{
    margin: auto;
   }

   .start_head{
    padding: 8px;
       color:  #123054;
       text-align: center;
   }
   .start_para{
       text-align: center;
       margin: auto;
       font-size: 14px;
       color:  #123054;

  }
   .card-body{
    padding: 6px 19px;
   }
   .search_paragraph{
    font-size: 13px;
    color:  #123054;
   }
   .right_para{
    text-align: end;
    font-size: 13px;

   }
   .right_head{
    text-align: end;
    color:  #123054;
    font-weight: 700;
   font-size: 17px;
   }
   .categories_left_side {
    display: flex;
    gap: 2px;
  }
  .icon-style{
    background-color: #233C54;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width:60px;
    height: 60px;

  }
   
  .start_img{
       display: flex;
       align-items: center;
       justify-content: center;
   }

   .Features_space{
    color:#00BBDC;
    text-align: center;
   }
  

 @media (max-width: 575px) {
  .icon-style{
    background-color: #233C54;
    object-fit: cover;
    border-radius: 50%;
    width:50px;
    height: 50px;
     margin: auto;
     display: none;
  
}
.search_paragraph{
  font-size: 18px;
 }
.search_engine{
   text-align: center;
   font-size: 23px !important;
  }
  .card-text{
    text-align: center;
  }
  .start_para{
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }
  .right_head{
    text-align: center;
    font-size: 23px !important;
    font-weight: 600;
  }
  .right_para{
    text-align: center;
    font-size: 18px;
    font-weight: 500;


  }

  
}

@media (max-width: 1192px) {
  .feature-image{
    display: none;
  }
  .search_engine{
    font-family: Lato;
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
      color: #123054;
    }
    .feature-responsive{
      padding-left: 20px;
      padding-right: 30px;
    }
    .start_para{
      padding: 20px;
      text-align: center;
    }
    .search_paragraph {
      font-size: 17px;
      color: #123054;
  }
  .right_para{
    text-align: center;
    font-size: 17px;
    font-weight: 500;


  }


}